const detectClipPathPolygonSupport = () => {
  let para = document.getElementById('clip-path-test')
  let compStyles = window.getComputedStyle(para)

  return compStyles.clipPath && compStyles.clipPath !== 'none' ? true : false
}

const root = document.documentElement

export const onClientEntry = () => {
  window.onload = () => {
    // Test browser supports polygon clip paths
    root.className += detectClipPathPolygonSupport() ? ' polygon' : ' no-polygon'
  }
}
